<template>
  <div class="subheader py-2 py-lg-4" v-bind:class="subheaderClasses" id="kt_subheader">
    <div
      class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div
        class="d-flex align-items-center flex-wrap mr-1"
        v-if="!menu[activeMenu]['menu'].show_breadcrumb_menu"
      >
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ title }}
        </h5>
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li>
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="{ name: breadcrumb.route }"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span class="text-muted" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div
        class="d-flex align-items-center flex-wrap ml-1 pl-2"
        v-if="
          menu &&
          activeMenu &&
          menu.hasOwnProperty(activeMenu) &&
          menu[activeMenu]['menu'].show_breadcrumb_menu
        "
      >
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 sub-menu"
        >
          <template v-for="(item, key) in menu[activeMenu].children">
            <router-link
              :to="item.link"
              v-if="item.link"
              class="text-muted mr-5"
              :key="key"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <li class="sub-menu-item" aria-haspopup="true" data-menu-toggle="hover">
                  <span
                    class="menu-text"
                    :class="[
                      isActive && 'sub-menu-active',
                      isExactActive && 'sub-menu-active',
                    ]"
                    >{{ $t(item.text) }}</span
                  >
                  <ul class="sub-header-sub-menu" v-if="item.children">
                    <template v-for="(subMenu, key) in item.children">
                      <router-link
                        :to="subMenu.link"
                        :key="key"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                      >
                        <a :href="href" @click="navigate">
                          <li
                            class="sub-header-sub-menu-item"
                            :class="[
                              isActive && 'sub-menu-child-active',
                              isExactActive && 'sub-menu-child-active',
                            ]"
                          >
                            {{ $t(subMenu.text) }}
                          </li>
                        </a>
                      </router-link>
                    </template>
                  </ul>
                </li>
              </a>
            </router-link>
            <li
              v-else
              :key="key"
              class="sub-menu-item"
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <span class="menu-text">
                {{ $t(item.text) }}
                <i class="fa fa-angle-down ml-1 mr-4" aria-hidden="true"></i>
              </span>
              <ul class="sub-header-sub-menu" v-if="item.children">
                <template v-for="(subMenu, key) in item.children">
                  <router-link
                    :to="subMenu.link"
                    :key="key"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <a :href="href" @click="navigate">
                      <li
                        class="sub-header-sub-menu-item"
                        :class="[
                          isActive && 'sub-menu-child-active',
                          isExactActive && 'sub-menu-child-active',
                        ]"
                      >
                        {{ $t(subMenu.text) }}
                      </li>
                    </a>
                  </router-link>
                </template>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
.menu-text {
  color: #6c7293;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}
.sub-menu-active {
  padding: 10px;
  border-radius: 3px;
  color: #fcfcfc;
  background: linear-gradient(
    180deg,
    rgb(102, 199, 232) 0%,
    rgb(64, 138, 198) 35%,
    rgb(70, 41, 133) 100%
  ) !important;
}

.sub-menu-child-active {
  border-radius: 3px;
  color: #fcfcfc !important;
  background: linear-gradient(
    180deg,
    rgb(102, 199, 232) 0%,
    rgb(64, 138, 198) 35%,
    rgb(70, 41, 133) 100%
  ) !important;
}
.sub-menu-item {
  &:hover {
    .sub-header-sub-menu {
      display: block;
    }
  }
}
.sub-header-sub-menu {
  position: absolute; /* Açılır menü mouse üzerine gelerek açıldığında aşağıdaki öğeleri kaydırması istenmiyor. Bu yüzden absolute. */
  display: none; /* Açılır menü li:hover olmadığı sürece gizlencektir */
  top: 100%; /* Top 100% değeri parent li'ye göre hesaplanır ve li'nin bittiği noktayı göstermektedir. */
  width: 200px;
  background-color: #fff;
  list-style: none;
  min-width: 150px;
  border-radius: 3px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  top: 35px;
  .sub-header-sub-menu-item {
    margin: 0px;
    position: relative; /* içerisinde ul position:absolute değerine sahip olduğu için kapsayıcı li relative olmalı */
    display: flex;
    color: #6c7293;
    padding: 10px 15px;
    width: 100%;
    border-bottom: 1px solid rgba(110, 110, 110, 0.34) !important;
    a {
      margin: 0px;
      padding: 0px;
    }
    &:hover {
      color: #fcfcfc;
      border-radius: 3px;
      background: linear-gradient(
        180deg,
        rgb(102, 199, 232) 0%,
        rgb(64, 138, 198) 35%,
        rgb(70, 41, 133) 100%
      ) !important;
    }
  }
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { BUNDLE, GET_SAMPLE_MENU_BADGE_NO } from "@/core/services/store/menu.module";
export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  watch: {
    title: {
      handler() {
        document.title = "Pulpopro - " + this.title;
      },
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    ...mapState({
      menuState: (state) => state.menu[BUNDLE],
    }),
    menu() {
      return this.authUser ? this.authUser.menu : null;
    },
    activeMenu() {
      return this.$route.meta.parent
        ? this.$route.meta.parent
        : this.$route.params.parent;
    },
    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    hasNestedChildren(menu) {
      return menu.hasOwnProperty("children");
    },
  },
  mounted() {
    this.$store.dispatch(GET_SAMPLE_MENU_BADGE_NO);
  },
};
</script>
