var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subheader py-2 py-lg-4",class:_vm.subheaderClasses,attrs:{"id":"kt_subheader"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap",class:{ 'container-fluid': _vm.widthFluid, container: !_vm.widthFluid }},[(!_vm.menu[_vm.activeMenu]['menu'].show_breadcrumb_menu)?_c('div',{staticClass:"d-flex align-items-center flex-wrap mr-1"},[_c('h5',{staticClass:"text-dark font-weight-bold my-2 mr-5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('ul',{staticClass:"breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{staticClass:"subheader-breadcrumbs-home",attrs:{"to":'/'}},[_c('i',{staticClass:"flaticon2-shelter text-muted icon-1x"})])],1),_vm._l((_vm.breadcrumbs),function(breadcrumb,i){return [_c('li',{key:`${i}-${breadcrumb.id}`,staticClass:"breadcrumb-item"},[(breadcrumb.route)?_c('router-link',{key:i,staticClass:"text-muted",attrs:{"to":{ name: breadcrumb.route }}},[_vm._v(" "+_vm._s(breadcrumb.title)+" ")]):_vm._e(),(!breadcrumb.route)?_c('span',{key:i,staticClass:"text-muted"},[_vm._v(" "+_vm._s(breadcrumb.title)+" ")]):_vm._e()],1)]})],2)]):_vm._e(),(
        _vm.menu &&
        _vm.activeMenu &&
        _vm.menu.hasOwnProperty(_vm.activeMenu) &&
        _vm.menu[_vm.activeMenu]['menu'].show_breadcrumb_menu
      )?_c('div',{staticClass:"d-flex align-items-center flex-wrap ml-1 pl-2"},[_c('ul',{staticClass:"breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 sub-menu"},[_vm._l((_vm.menu[_vm.activeMenu].children),function(item,key){return [(item.link)?_c('router-link',{key:key,staticClass:"text-muted mr-5",attrs:{"to":item.link},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('li',{staticClass:"sub-menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('span',{staticClass:"menu-text",class:[
                    isActive && 'sub-menu-active',
                    isExactActive && 'sub-menu-active',
                  ]},[_vm._v(_vm._s(_vm.$t(item.text)))]),(item.children)?_c('ul',{staticClass:"sub-header-sub-menu"},[_vm._l((item.children),function(subMenu,key){return [_c('router-link',{key:key,attrs:{"to":subMenu.link},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('li',{staticClass:"sub-header-sub-menu-item",class:[
                            isActive && 'sub-menu-child-active',
                            isExactActive && 'sub-menu-child-active',
                          ]},[_vm._v(" "+_vm._s(_vm.$t(subMenu.text))+" ")])])]}}],null,true)})]})],2):_vm._e()])])]}}],null,true)}):_c('li',{key:key,staticClass:"sub-menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" "),_c('i',{staticClass:"fa fa-angle-down ml-1 mr-4",attrs:{"aria-hidden":"true"}})]),(item.children)?_c('ul',{staticClass:"sub-header-sub-menu"},[_vm._l((item.children),function(subMenu,key){return [_c('router-link',{key:key,attrs:{"to":subMenu.link},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('li',{staticClass:"sub-header-sub-menu-item",class:[
                        isActive && 'sub-menu-child-active',
                        isExactActive && 'sub-menu-child-active',
                      ]},[_vm._v(" "+_vm._s(_vm.$t(subMenu.text))+" ")])])]}}],null,true)})]})],2):_vm._e()])]})],2)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }